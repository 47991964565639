<template>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    :finished-text="commentmain == 0 ? '来占个沙发吧' : '已显示全部评论'"
    @load="loadMore"
  >
    <ul class="commentmain">
      <li
        class="items"
        :data-id="item.v_c_id"
        v-for="(item, index) in commentmain"
        :key="index"
      >
        <div class="boxes">
          <span class="img"><img :src="item.headimg" alt="" /></span>
        </div>
        <div class="boxes">
          <div class="top">
            <p class="name">{{ item.v_c_member_name }}</p>
            <span
              :ref="refLiId"
              :class="{ active: item.praise_state == true }"
              @click="giveLike(item.v_c_id, item)"
              class="icon"
              ><i></i
              >{{
                item.comment_praise_num == 0
                  ? "赞"
                  : item.comment_praise_num || "赞"
              }}</span
            >
          </div>
          <div class="text">{{ item.v_c_content }}</div>
          <div class="time">{{ item.v_c_publish_time }}</div>
        </div>
      </li>
    </ul>
  </van-list>
</template>

<script>
import {
  nextTick,
  ref,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
} from "vue";
import { commentPraise, moreComment } from "@/api/api";

export default defineComponent({
  name: "CommentList",
  props: {
    id: Number,
    Newlist: Array,
    live: String,
  },
  setup(props, context) {
    const state = reactive({
      i: 1,
      commentmain: [],
      page: 0, //页数
      loading: false,
      finished: false,
      timer: null,
      praiseState: 0,
    });
    const childMethod = (flag) => {
      state.commentmain = [flag, ...state.commentmain];
    };
    const loadMore = async () => {
      state.page += 1;
      await onLoad();
    };
    const onLoad = () => {
      state.timer = setTimeout(() => {
        const data = {
          data: `{"v_id":${props.id},"is_live":${props.live},"page":${state.page}}`,
        };
        moreComment(data)
          .then(({ data: { data } }) => {
            state.commentmain = [...state.commentmain, ...data.comment_list];
            state.loading = false;
            if (state.page) {
              state.finished = true;
            } else {
              state.finished = false;
            }
          })
          .catch((err) => console.log("失败了" + err));
      }, 500);
    };

    const giveLike = (vcId, itemps) => {
      let data = {
        data: `{"v_c_id":${vcId}}`,
      };
      commentPraise(data)
        .then((data) => {
          console.log(data);
          if (data.data.code == 30000) {
            context.emit("parentEvent", true);
            return false;
          }
          if (itemps.praise_state == undefined) {
            itemps.praise_state = 0;
            itemps.comment_praise_num = 0;
          }
          itemps.praise_state = !itemps.praise_state;
          itemps.praise_state == 1
            ? (itemps.comment_praise_num = +itemps.comment_praise_num + 1)
            : (itemps.comment_praise_num = +itemps.comment_praise_num - 1);
        })
        .catch((err) => console.log("失败了" + err));
    };
    return {
      giveLike,
      childMethod,
      onLoad,
      loadMore,
      ...toRefs(state),
    };
  },
});
</script>

 <style lang="scss">
.commentmain {
  text-align: left;
  margin: 40px 20px;
  // padding-bottom: 50px;
  .items {
    margin-bottom: 40px;
    display: flex;
    overflow: hidden;
    .boxes {
      &:nth-child(2) {
        width: 100%;
      }
      .img {
        display: block;
        margin-right: 20px;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .top {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      .name {
        font-size: 34px;
        font-weight: 500;
        color: #d43c3d;
        line-height: 1.2;
      }
      .icon {
        margin-top: 8px;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active {
          color: #d43c3d;
          i {
            background: url(../assets/icon/icon-praise-active.png) no-repeat;
            background-size: 30px;
          }
        }
        i {
          position: relative;
          top: -5px;
          margin-right: 5px;
          display: block;
          background: url(../assets/icon/icon-praise.png) no-repeat;
          width: 30px;
          height: 30px;
          background-size: 30px;
        }
      }
    }
    .text {
      margin-bottom: 10px;
      font-size: 30px;
      font-weight: 500;
      color: #000000;
      line-height: 45px;
      overflow: hidden;
    }
    .time {
      font-size: 24px;
      font-weight: 500;
      color: #777;
      line-height: 1.2;
    }
  }
}

.vsvsectionbox {
  .items {
    .top {
      .name {
        color: #fff;
        opacity: 0.6;
      }
      .icon {
        color: #fff;
        opacity: 0.6;
        &.active {
          color: #d43c3d;
          i {
            background: url(../assets/icon/vsv-icon-praise-active.png) no-repeat;
            background-size: 30px;
          }
        }
        i {
          position: relative;
          top: -5px;
          margin-right: 5px;
          display: block;
          background: url(../assets/icon/vsv-icon-praise.png) no-repeat;
          width: 30px;
          height: 30px;
          background-size: 30px;
        }
      }
    }
    .text {
      color: #fff;
    }
    .time {
      color: #fff;
      opacity: 0.6;
    }
  }
}
</style>

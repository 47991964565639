<template>
  <van-share-sheet
    v-model:show="childData"
    title="立即分享给好友"
    :options="options"
    @select="onSelect"
    safe-area-inset-bottom
  />
</template>

<script>
import { Toast } from "vant";
export default {
  name: "Share",
  props: {
    ss: Boolean
  },
  data() {
    return {
      childData: false,
      options: [
        { name: "微信", icon: "wechat" },
        // { name: "微博", icon: "weibo" },
        { name: "复制链接", icon: "link" },
        { name: "分享海报", icon: "poster" }
      ]
    };
  },
  methods: {
    onSelect(option) {
      Toast(option.name);
      this.childData = false;
    }
  }
};
</script>

<style lang="scss">
.van-share-sheet__header {
  padding: 10px;
}
.van-share-sheet__title {
  margin-top: 30px;
  font-size: 30px;
  line-height: 1.2;
}
.van-share-sheet__description {
  margin-top: 0.106667rem;
  font-size: 0.16rem;
  line-height: 0.213333rem;
}
.van-share-sheet__options {
  padding: 20px;
  box-sizing: border-box;
}
.van-share-sheet__options::-webkit-scrollbar {
  height: 0;
}
.van-share-sheet__icon {
  width:80px;
  height:80px;
  margin: 0 40px;
}
.van-share-sheet__name {
  margin-top:15px;
  padding: 0 10px;
  font-size: 26px;
}
.van-share-sheet__option-description {
  padding: 0 4px;
  font-size: 24px;
}
.van-share-sheet__cancel {
  font-size: 30px;
  line-height: 3;
}
.van-share-sheet__cancel::before {
  height: 10px;
}
 
</style>

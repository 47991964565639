
<template>
  <div>
    <div
      class="prism-player"
      id="J_prismPlayer"
      @click="handleNodeClick()"
    ></div>
  </div>
</template>
<script>
import {
  ref,
  watch,
  reactive,
  onMounted,
  defineComponent,
  onBeforeUpdate,
  computed,
} from "vue";
// https://blog.csdn.net/asing1elife/article/details/89249228 阿里云播放器的使用 下边是文档
// https://help.aliyun.com/document_detail/125572.html?spm=a2c4g.11186623.6.1113.95ea6fc57bPkgK#h2-u64ADu653Eu5668u4E8Bu4EF63
// https://player.alicdn.com/aliplayer/setting/setting.html //模拟
const jsPath = "https://res.sctx.com/videoapp/aliplayer-min.js";
const cssPath = "https://res.sctx.com/videoapp/aliplayer-min.css";
// const jsPath = "http://g.alicdn.com/de/prismplayer/2.8.1/aliplayer-h5-min.js";
// const cssPath =
// "http://g.alicdn.com/de/prismplayer/2.8.1/skins/default/aliplayer-min.css";

export default defineComponent({
  name: "AliyunPlayer",
  components: {},
  props: {
    chartData: Array,
  },
  setup(props, context) {
    const initialize = () => {
      // 判断播放器全局实例是否已存在，只有当播放器依赖的 JS 加载完成后，播放器的全局实例才存在
      if (!window.Aliplayer) {
        // 尝试获取播放器的 JS 标签
        let inPlayerScriptTag = document.getElementById("inPlayerScriptTag");

        // 判断JS是否已存在
        if (!inPlayerScriptTag) {
          // 不存在则创建 JS 标签
          inPlayerScriptTag = document.createElement("script");
          inPlayerScriptTag.type = "text/javascript";
          // 指定 JS 的地址
          inPlayerScriptTag.src = jsPath;
          // 指定 JS 的 ID
          inPlayerScriptTag.id = "inPlayerScriptTag";

          // JS 不存在说明 CSS 也不存在，则创建 CSS 标签
          let inPlayerLinkTag = document.createElement("link");
          inPlayerLinkTag.type = "text/css";
          inPlayerLinkTag.rel = "stylesheet";
          // 指定 CSS 的地址
          inPlayerLinkTag.href = cssPath;

          // 获取页面的 <head> 标签
          let head = document.getElementsByTagName("head")[0];
          // 将 JS 和 CSS 插入到 DOM 中
          head.appendChild(inPlayerScriptTag);
          head.appendChild(inPlayerLinkTag);
        }

        // JS 插入并加载完成
        if (inPlayerScriptTag.loaded) {
          // 初始化播放器
          initPlayer();
        } else {
          // JS 没有加载完成，则监听 JS 的加载事件
          inPlayerScriptTag.addEventListener("load", () => {
            // JS 确认加载完成后，初始化播放器
            initPlayer();
          });
        }
      } else {
        // 全局实例存在则直接初始化播放器
        initPlayer();
      }
    };
    let player = ref(null);
    const initPlayer = () => {
      // "//player.alicdn.com/video/aliyunmedia.mp4"props.chartData[1]
      player = new Aliplayer({
        id: "J_prismPlayer", // 容器id
        source: props.chartData[1], //视频地址
        cover: props.chartData[0], //播放器封面图
        autoplay: false, // 是否自动播放
        width: "100%", // 播放器宽度
        height:
          props.chartData[2] == 100
            ? props.chartData[2] + "vh"
            : props.chartData[2] + "rem", // 播放器高度
        isLive: false,
        rePlay: false,
        playsinline: true,
        preload: true,
        controlBarVisibility: "click",
        showBarTime: 5000,
        useH5Prism: true,
        skinLayout: [
          {
            name: "bigPlayButton",
            align: "blabs",
            x: 0,
            y: 0,
          },
          {
            name: "H5Loading",
            align: "cc",
            x: 0,
            y: 0,
          },
          {
            name: "errorDisplay",
            align: "tlabs",
            x: 0,
            y: 0,
          },
          {
            name: "infoDisplay",
          },
          {
            name: "tooltip",
            align: "blabs",
            x: 0,
            y: 56,
          },
          {
            name: "thumbnail",
          },
          {
            name: "controlBar",
            align: "blabs",
            x: 0,
            y: 0,
            children: [
              {
                name: "progress",
                align: "blabs",
                x: 0,
                y: 44,
              },
              {
                name: "playButton",
                align: "tl",
                x: 15,
                y: 12,
              },
              {
                name: "timeDisplay",
                align: "tl",
                x: 10,
                y: 7,
              },
              {
                name: "fullScreenButton",
                align: "tr",
                x: 10,
                y: 12,
              },
            ],
          },
        ],
      });
    };
    onMounted(() => {
      initialize();
      if (props.chartData) {
        // console.log(props.chartData);
      }

      // console.log("彭晶晶", ...props.chartData);
    });

    const filterNode = (value, data) => {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    };
    const handleNodeClick = () => {
      console.log("点击");
      let _video = document.querySelector("video");
  
      if (props.chartData[2] == 100) {
        player.on("play", function (e) {
          _video.removeEventListener("click", play);
          _video.addEventListener("click", pause);
          console.log("play");
        });
        player.on("pause", function (e) {
          _video.removeEventListener("click", pause);
          _video.addEventListener("click", play);
          console.log("pause");
        });
      }
    };
    const play = () => {
      if (player) player.play();
    };
    const pause = () => {
      if (player) player.pause();
      console.log("dblclick");
    };

    return {
      play,
      pause,
      initPlayer,
      initialize,
      filterNode,
      handleNodeClick,
    };
  },
});
</script>

 <style lang='scss'>
.el-col {
  padding: 0;
}
.prism-big-play-btn {
  &.pause {
    display: block !important;
  }
}
</style>